@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "M PLUS 2", sans-serif !important;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  max-width: 1900px;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10000;
}

.transition-top {
  transition: top 0.2s ease-in-out;
}

/* 
.stickyScroll {
  display: block;
  position: sticky;
  top: 0;
  z-index: 1000;
} */
